<template>
  <div class="w-100">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <EditIndicator
      v-if="showEditModal"
      :dashboardStats="dashboardStats"
      :allIndicators="allIndicators"
      v-bind:show="showEditModal"
      @close="closeEditModal"
      @alertSuccess="alertSuccess"
      @reload="getUserIndicators"
      @alertError="alertError"
    />
    <div class="col-12 mt-4">
      <div class="dashboard_card dashboard_card_left">
        <div class="dashboard_card_header">
          <h5 class="dashboard_card_header__title text--bold text--capital">
            Indicators
          </h5>
          <button
            type="button"
            class="button button--grey text--capital"
            data-modal="#configureIndicator"
            @click="openEditModal()"
          >
            <span class="d-inline-flex mr-2">Configure Indicator</span>
            <img src="@/assets/img/settings.svg" alt="" srcset="" />
          </button>
        </div>
        <div>
          <div class="row mb-5">
            <div
              v-for="(val, key) in indicators"
              class="col-4 ml-auto p-2"
              :key="key"
            >
              <div class="task-card h-100">
                <span class="task-subtitle overflow-auto text--capital d-block">
                  {{ key }}
                </span>
                <span class="task-text overflow-auto text--600 d-block mt-1">
                  {{ formatValue(val) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditIndicator from "./indicator/edit";
import { mapState } from "vuex";
import {
  GET_ALL_INDICATORS,
  GET_DASHBOARD_STATS,
} from "@/core/services/store/types";
export default {
  name: "Indicator",
  data: function () {
    return {
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Indicator has been updated",
      },
      process: false,
      showCreateModal: false,
      showEditModal: false,
    };
  },
  props: {
    indicators: Object,
  },
  components: {
    EditIndicator,
  },
  methods: {
    getAllIndicators() {
      this.$store.dispatch(GET_ALL_INDICATORS);
    },
    getUserIndicators() {
      this.$store.dispatch(GET_DASHBOARD_STATS);
    },
    formatValue(value = 0) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    openEditModal() {
      // this.indicator = row;
      this.showEditModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
  },
  computed: {
    ...mapState({
      allIndicators: (state) => state.deposit?.allIndicators?.data || [],
      dashboardStats: (state) => state.deposit?.dashboardStats?.data || {},
      loading: (state) => state.deposit.loadingdashboardStats,
      loadingAll: (state) => state.deposit.loadingAllIndicators,
    }),
  },
  mounted() {
    this.getAllIndicators();
    this.getUserIndicators();
  },
};
</script>
