<template>
  <div class="w-100">
    <div class="col-12 mb-4">
      <div class="dashboard_card dashboard_card_left">
        <div class="dashboard_card_header">
          <h5 class="dashboard_card_header__title text--bold text--capital">
            task
          </h5>
          <div>
            <button
              type="button"
              class="mx-2 button button-new text--capital"
              @click="openTaskModal"
              v-if="userCanCreateTask"
            >
              <ion-icon name="add-outline" class="text--600"></ion-icon>
              <span class="d-inline-flex ml-2 text--600">new task</span>
            </button>
            <router-link
              to="/all-tasks"
              class="mx-2 button button-add text--capital"
              v-if="userCanViewTask"
            >
              <img src="@/assets/img/category.svg" alt="" srcset="" />
              <span class="d-inline-flex ml-2">all task</span>
            </router-link>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col">
            <div class="task-late">
              <div class="mb-1">
                <span class="task-icon">
                  <img src="@/assets/img/redcdr.svg" alt="" srcset="" />
                </span>
                <span class="task-subtitle text--capital"> overdue </span>
              </div>
              <div>
                <span class="task-text--big text--600">
                  {{ stats.overDue ? stats.overDue : 0 }}
                </span>
              </div>
              <div class="task-indicator">
                <div class="task-indicator-child task-indicator--danger"></div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="task-due">
              <div class="mb-1">
                <span class="task-icon">
                  <img src="@/assets/img/orgcdr.svg" alt="" srcset="" />
                </span>
                <span class="task-subtitle text--capital"> Due Today </span>
              </div>
              <div>
                <span class="task-text--big text--600">
                  {{ stats.due ? stats.due : 0 }}
                </span>
              </div>
              <div class="task-indicator">
                <div class="task-indicator-child task-indicator--primary"></div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="task-upcoming">
              <div class="mb-1">
                <span class="task-icon">
                  <img src="@/assets/img/grncdr.svg" alt="" srcset="" />
                </span>
                <span class="task-subtitle text--capital"> upcoming </span>
              </div>
              <div>
                <span class="task-text--big text--600">
                  {{ stats.upcoming ? stats.upcoming : 0 }}
                </span>
              </div>
              <div class="task-indicator">
                <div class="task-indicator-child task-indicator--success"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateTask
      :show="newTaskModal"
      @close="openTaskModal"
      @error-creating-task="errorCreatingTask"
      @task-created-successfully="taskCreatedSuccessfully"
    />
  </div>
</template>
<script>
import CreateTask from "@/view/pages/deposit/task/createTask.vue";
export default {
  name: "Task",
  props: {
    stats: Object,
    userCanCreateTask: Boolean,
    userCanViewTask: Boolean,
  },
  components: {
    CreateTask,
  },

  data() {
    return {
      newTaskModal: false,
    };
  },

  methods: {
    openTaskModal() {
      this.newTaskModal = !this.newTaskModal;
    },
    errorCreatingTask(value) {
      this.$emit("error-creating-task", value);
    },
    taskCreatedSuccessfully(value) {
      this.$emit("task-created-successfully", value);
    },
  },
};
</script>
