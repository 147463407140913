<template>
  <div class="row h-100">
    <div class="col-7 h-100">
      <div class="row">
        <Task
          @error-creating-task="errorCreatingTask"
          @task-created-successfully="taskCreatedSuccessfully"
          :userCanCreateTask="userCanCreateTask"
          :userCanViewTask="userCanViewTask"
          :stats="dashboardStats"
        />
        <Indicator :indicators="dashboardStats.userIndicators" />
      </div>
    </div>
    <div class="col-5 h-70">
      <Activities
        :dashboard="dashboardStats"
        @configureSuccess="getDashboardStats"
      />
    </div>

    <!-- Alert notification -->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Task from "@/view/pages/deposit/task/task.vue";
import Indicator from "@/view/pages/deposit/task/indicator.vue";
import Activities from "@/view/pages/deposit/task/activities.vue";
import { GET_DASHBOARD_STATS } from "@/core/services/store/types";
import { Role } from "@/core/services/role.js";
export default {
  name: "TaskIndex",
  data() {
    return {
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
    };
  },
  components: {
    Task,
    Indicator,
    Activities,
  },
  methods: {
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    taskCreatedSuccessfully(value) {
      this.alertSuccess(value);
    },
    errorCreatingTask(value) {
      this.alertError(value);
    },
    getDashboardStats() {
      this.$store.dispatch(GET_DASHBOARD_STATS);
    },
  },
  mounted() {
    this.getDashboardStats();
  },
  computed: {
    ...mapState({
      dashboardStats: (state) => state.deposit?.dashboardStats?.data || {},
      loading: (state) => state.deposit.loadingdashboardStats,
      user: (state) => state.auth.user,
    }),
    userCanCreateTask() {
      const task = this.user.scopes.find(item => item.scopeName === Role.Tasks);
      const { permissionsList } = task;
      return permissionsList.CanCreateTask;
    },
    userCanViewTask() {
      const task = this.user.scopes.find(item => item.scopeName === Role.Tasks);
      const { permissionsList } = task;
      return permissionsList.CanViewTask;
    },
  },
};
</script>
<style scoped>
.page__inner {
  padding: 0 !important;
}
.page__content--wider {
  overflow-y: hidden;
}
</style>
